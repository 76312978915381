import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  filePropType,
  metaProductPropType,
  productVariantPropType,
  urlLocationPropType,
  siteDataPropType,
} from 'lib/prop-types'
import Helmet from 'react-helmet'
import Image from 'components/Image'
import Button from 'components/Button'
import Link from 'components/Link'
import FileLink from 'components/Products/FileLink'
import GoogleShoppingButton from 'components/GoogleShoppingButton'
import formatMoney from 'lib/format-money'
import createLink from 'lib/create-link'
import createLongTitle from 'lib/create-long-title'
import SiteMetadata from 'lib/SiteMetadata'
import getMetaTags from 'lib/get-meta-tags'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import Layout from 'components/Layout'
import ContactForm from 'components/ContactForm'
import Breadcrumbs from 'components/Contentful/Breadcrumbs'
import imageUrlForProduct from 'lib/image-url-for-product'
import { product } from '../../../lib/structured-data'

class ProductDetails extends React.Component {
  constructor(props) {
    const { chosenVariant } = props.pageContext
    super(props)

    const queries = props.location.search
      .substr(1, props.location.search.length)
      .split('&')
    const fromGoogleShopping = queries.some(
      query => query === 'source=google-shopping'
    )

    this.state = {
      image: chosenVariant.defaultImage,
      fromGoogleShopping: fromGoogleShopping,
    }
    if (chosenVariant.defaultImage) {
      this.allImages = [chosenVariant.defaultImage]
    }
    if (chosenVariant.additionalImages) {
      this.allImages.push(...chosenVariant.additionalImages)
    }
    this.siteMetadata = props.data.site.siteMetadata
  }

  renderProductVariant(productVariant, chosenVariant) {
    return (
      <Fragment>
        <div className="product-img--tiny b-r--3">
          {productVariant.isAvailableOnline && productVariant.defaultImage ? (
            <Image
              name={imageUrlForProduct(productVariant)}
              size="small"
              alt={productVariant.defaultImage.alt}
            />
          ) : null}
        </div>
        <div className="product__variants--price">
          {chosenVariant.id === productVariant.id ? (
            <Fragment>
              <div className="highlight__badge badge--primary badge--top-left">
                Ausgewählt <FontAwesomeIcon icon={faCheck} />
              </div>
              {productVariant.price === 0
                ? null
                : formatMoney(productVariant.price)}
            </Fragment>
          ) : productVariant.price === 0 ? null : (
            formatMoney(productVariant.price)
          )}
        </div>
        <div className="product__variants--title">
          {createLongTitle(productVariant)}
        </div>
      </Fragment>
    )
  }

  scrollThroughImages(direction, allImages) {
    const currentIndex = allImages.findIndex(
      image => image.id === this.state.image.id
    )
    let newIndex = currentIndex + direction
    if (newIndex >= allImages.length) {
      newIndex = 0
    }
    if (newIndex < 0) {
      newIndex = allImages.length - 1
    }

    this.setState({ image: allImages[newIndex] })
  }

  renderImages() {
    const allImages = this.allImages
    return (
      <Fragment>
        {this.state.image ? (
          <div className="product-img__container b--grey-light b-r--3">
            {allImages && allImages.length > 1 ? (
              <Fragment>
                <div className="img__controls">
                  <a
                    onClick={e => {
                      e.preventDefault()
                      this.scrollThroughImages(-1, allImages)
                    }}
                    href="#"
                    className="control--left"
                  >
                    <span className="control--icon">
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </span>
                  </a>
                  <a
                    onClick={e => {
                      e.preventDefault()
                      this.scrollThroughImages(+1, allImages)
                    }}
                    href="#"
                    className="control--right"
                  >
                    <span className="control--icon">
                      <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                  </a>
                </div>
                <div className="product__img--thumbnails">
                  {this.imageThumbnails()}
                </div>
              </Fragment>
            ) : null}

            <div className="product__img">
              <Image
                name={imageUrlForProduct(
                  this.props.pageContext.chosenVariant,
                  this.state.image
                )}
                size="huge"
                alt={this.state.image.alt}
              />
            </div>
          </div>
        ) : null}
      </Fragment>
    )
  }

  imageThumbnails() {
    return this.allImages.map(image => {
      return (
        <a
          onClick={e => {
            e.preventDefault()
            this.setState({ image: image })
          }}
          href="#"
          key={image.id}
          className="thumbnail hover--shadow"
        >
          <Image
            name={imageUrlForProduct(
              this.props.pageContext.chosenVariant,
              image
            )}
            size="small"
            className={image.id === this.state.image.id ? 'active' : null}
            alt={image.alt}
          />
        </a>
      )
    })
  }

  isSolarProduct(chosenVariant) {
    const solarCategories = [
      'Photovoltaikmodul',
      'Batteriespeicher',
      'Wechselrichter',
      'Ersatzstrom',
    ]
    return solarCategories.includes(chosenVariant.inCategories[0]?.value)
  }

  breadcrumbTrail(chosenVariant) {
    const nonExistentCategoryPages = ['regler']
    const trail = []
    if (chosenVariant.inCategories[0]) {
      trail.push(chosenVariant.inCategories[0].value)
    }
    if (chosenVariant.inCategories[1]) {
      let secondCategory = chosenVariant.inCategories[1].value
      if (chosenVariant.inCategories[2]) {
        secondCategory += ' ' + chosenVariant.inCategories[2].value
      }
      trail.push(secondCategory)
    }
    return trail.filter(page => !nonExistentCategoryPages.includes(page))
  }

  render() {
    const { metaProduct, chosenVariant, downloadableFiles, otherFiles } =
      this.props.pageContext

    return (
      <Layout>
        {this.isSolarProduct(chosenVariant) ? (
          <Breadcrumbs
            links={[
              {
                label: 'Photovoltaik',
                url: 'waermepumpe-photovoltaik',
              },
              {
                label: createLongTitle(chosenVariant),
                url: this.props.path,
              },
            ]}
          />
        ) : (
          <Breadcrumbs
            trail={[
              ...this.breadcrumbTrail(chosenVariant),
              createLongTitle(chosenVariant),
            ]}
          />
        )}
        <div className="section">
          <div className="section__inner">
            <div className="product__wrapper padding--v cf">
              <Fragment>
                <Helmet
                  title={
                    chosenVariant.seoTitle || createLongTitle(chosenVariant)
                  }
                  meta={getMetaTags(chosenVariant)}
                >
                  <script type="application/ld+json">
                    {JSON.stringify(
                      product(chosenVariant, {
                        siteUrl: this.siteMetadata.siteUrl,
                      })
                    )}
                  </script>
                </Helmet>
                <div className="product__hero">
                  <h1>{createLongTitle(chosenVariant)}</h1>
                  {this.renderImages()}
                  {metaProduct.productVariants.length > 1 ? (
                    <div className="product__variants">
                      {metaProduct.productVariants.map(productVariant => {
                        return productVariant.isAvailableOnline ? (
                          <Link
                            key={productVariant.id}
                            to={createLink.toProduct(productVariant)}
                            className={`product__variants--item hover--shadow b-r--3 ${
                              chosenVariant.id === productVariant.id
                                ? 'activeVariant'
                                : ''
                            }`}
                          >
                            {this.renderProductVariant(
                              productVariant,
                              chosenVariant
                            )}
                          </Link>
                        ) : (
                          <div
                            key={productVariant.id}
                            className={`product__variants--item hover--shadow b-r--3 ${
                              chosenVariant.id === productVariant.id
                                ? 'activeVariant'
                                : ''
                            }`}
                          >
                            {this.renderProductVariant(
                              productVariant,
                              chosenVariant
                            )}
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                </div>

                {this.state.fromGoogleShopping ? (
                  <div className="contact__side-box b-r--3 mb2">
                    <GoogleShoppingButton
                      type="product"
                      item={chosenVariant}
                      history={
                        this.props.location.pathname +
                        this.props.location.search
                      }
                    />
                  </div>
                ) : null}

                <div className="contact__side-box b-r--3">
                  <ContactForm />
                </div>

                <div className="product__content margin--t">
                  <h3 className="section__sub-headline">Beschreibung</h3>
                  <div className="pre-line">{chosenVariant.description}</div>
                  {chosenVariant.attributes ? <h3>Spezifikationen</h3> : null}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: chosenVariant.attributes,
                    }}
                    className="product__link-section"
                  />
                  {downloadableFiles.length > 0 ? (
                    <h3 className="section__sub-headline">Downloads</h3>
                  ) : null}
                  {downloadableFiles.map(file => {
                    return (
                      <FileLink
                        downloadable
                        key={`download-${file.name}`}
                        name={file.name}
                        type={file.type}
                        size={file.size}
                        modified={file.modified}
                      />
                    )
                  })}
                  {otherFiles.length > 0 ? (
                    <Fragment>
                      <h3 className="section__sub-headline">
                        Weitere Dokumente zu diesem Produkt
                      </h3>
                      <p>
                        Für die weiteren verfügbaren Dokumente zu diesem Produkt
                        erstellen wir Ihnen gern eine Offerte.
                      </p>
                      <p>
                        <Button
                          link={{
                            url: `${this.siteMetadata.siteUrl}/kontakt/`,
                          }}
                          className="btn btn--primary btn--small"
                        >
                          Offerte anfragen
                        </Button>
                      </p>
                      {otherFiles.map(file => (
                        <FileLink
                          key={file.name}
                          name={file.name}
                          type={file.type}
                          size={file.size}
                          modified={file.modified}
                        />
                      ))}
                    </Fragment>
                  ) : null}
                </div>
              </Fragment>
            </div>
            <div className="text--hint">
              *Alle Preise und Angaben ohne Gewähr. Änderungen in der
              Zwischenzeit sind möglich. <br />
              *Alle Preise exkl. MwSt.
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

ProductDetails.propTypes = {
  pageContext: PropTypes.shape({
    metaProduct: metaProductPropType,
    chosenVariant: productVariantPropType,
    downloadableFiles: PropTypes.arrayOf(filePropType),
    otherFiles: PropTypes.arrayOf(filePropType),
  }),
  data: siteDataPropType,
  location: urlLocationPropType,
  path: PropTypes.string,
}

const ProductDetailsSite = props => (
  <SiteMetadata render={data => <ProductDetails data={data} {...props} />} />
)

export default ProductDetailsSite
