import React from 'react'
import PropTypes from 'prop-types'
import { withPrefix } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faDownload } from '@fortawesome/free-solid-svg-icons'

const formatDate = date =>
  new Date(date).toLocaleDateString('de-CH', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })

const transformSize = size => {
  const units = ['Byte', 'KB', 'MB', 'GB']
  let i = 0
  while (size > 1024) {
    size = size / 1024
    i++
  }
  return `${size.toFixed(2)} ${units[i]}`
}

const FileLink = props => {
  const { size, modified, name, type } = props
  const sizeAndModified = `${transformSize(size)} | ${formatDate(modified)}`
  const fileName = `${name}.${type.toLowerCase()}`

  if (props.downloadable) {
    return (
      <a
        href={withPrefix(`/files/${fileName}`)}
        className="download-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFilePdf} className="download-link--icon" />{' '}
        <span className="download-link__title">
          {fileName}{' '}
          <span className="download-link__addon">{sizeAndModified}</span>
        </span>
        <button className="btn btn--outlined btn--small btn--with-icon">
          <FontAwesomeIcon icon={faDownload} />
          Download
        </button>
      </a>
    )
  }

  return (
    <div className="download-link o-60">
      <FontAwesomeIcon icon={faFilePdf} className="download-link--icon" />{' '}
      <span className="download-link__title">
        {fileName}{' '}
        <span className="download-link__addon">{sizeAndModified}</span>
      </span>
    </div>
  )
}

FileLink.propTypes = {
  downloadable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  modified: PropTypes.string,
}

export default FileLink
