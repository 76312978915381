import React from 'react'
import { Fragment } from 'react'
import Recaptcha, { generateCaptchaToken } from 'lib/recaptcha'
import invokeNetlifyLambda from 'lib/invoke-netlify-lambda'
import pushDataLayer from 'lib/push-data-layer'
import createStore from 'lib/util/create-store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamation,
  faCheck,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import ContactFormFields from 'components/ContactFormFields'
import Link from 'components/Link'
import Button from 'components/Button'

const contactStore = createStore('contact')
const defaultContact = {
  firstName: '',
  lastName: '',
  street: '',
  zipCode: '',
  city: '',
  email: '',
  phone: '',
  notes: '',
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { contact: { ...(contactStore.load() || defaultContact) } }
  }

  render() {
    return (
      <Fragment>
        <h2 className="mt0">Interesse?</h2>
        <p>
          Wir übernehmen den kompletten Umbau der alten Heizung durch eine
          moderne Wärmepumpe. Zusammen mit unseren lokalen Profis.
        </p>
        <form
          onSubmit={e => {
            e.preventDefault()
            pushDataLayer('set', 'user_data', {
              email: this.state.contact.email,
              phone_number: this.state.contact.phone,
            })
            pushDataLayer('event', 'contact', { type: 'Produktkontakt' })
            const GCLID = e.target?.GCLID?.value
            generateCaptchaToken().then(token =>
              invokeNetlifyLambda
                .post(
                  'pardot-submit',
                  JSON.stringify({
                    ...this.state.contact,
                    captchaToken: token,
                    typeOfForm: 'Product',
                    GCLID,
                  })
                )
                .then(() =>
                  this.setState({
                    formSuccess: true,
                    formError: false,
                  })
                )
                .catch(() =>
                  this.setState({
                    formError: true,
                    formSuccess: false,
                  })
                )
            )
          }}
        >
          <Recaptcha />
          <ContactFormFields
            state={this.state.contact}
            handleChange={e => {
              const newContact = {
                ...this.state.contact,
                [e.target.name]: e.target.value,
              }
              this.setState({
                contact: newContact,
              })
              contactStore.save(newContact)
            }}
          />
          <div className="w-100 tr">
            <Button className="btn--primary">Senden</Button>
          </div>
        </form>
        {this.state.formSuccess ? (
          <Fragment>
            <div className="callout callout--success mb3 mt3">
              <span className="callout__icon">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <span className="callout__text">
                Vielen Dank für Ihre Anfrage, wir werden uns mit Ihnen in
                Verbindung setzen.
              </span>
            </div>
            <div className="tr">
              <Link to="/konfigurator">
                Zum Heizungscheck <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </Fragment>
        ) : this.state.formError ? (
          <div className="callout callout--warning mb3 mt3">
            <span className="callout__icon">
              <FontAwesomeIcon icon={faExclamation} />
            </span>
            <span className="callout__text">
              Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.
            </span>
          </div>
        ) : null}
      </Fragment>
    )
  }
}

export default ContactForm
