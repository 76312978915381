const escape = require('lodash.escape')

const getMetaTags = item => {
  // TODO: wait for Roger to finally reply
  // const metaTags = [{ name: 'keywords', content: 'Meta Keywords' }]
  const metaTags = []
  if (item.seoDescription) {
    metaTags.push({
      name: 'description',
      content: escape(item.seoDescription),
    })
  }
  if (item.seoKeyword) {
    metaTags.push({ name: 'keywords', content: item.seoKeyword })
  }
  return metaTags
}

export default getMetaTags
