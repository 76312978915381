import React from 'react'
import Button from 'components/Button'

const GoogleShoppingButton = props => {
  return (
    <Button
      link={{ url: '/order' }}
      className="btn--primary google-shopping"
      state={props}
    >
      Jetzt Bestellen
    </Button>
  )
}

export default GoogleShoppingButton
